<template>
  <Layout>
    <div class="section-content max-w-4xl mx-auto">
      <div class="form">
        <div class="text-center py-5 px-2">
          <i class="fa fa-check-circle text-4xl text-lime-600 mb-2"></i>
          <h5 class="mb-4 text-lime-600">Confirmação realizada com sucesso!</h5>
          <p>
            Obrigado por participar das eleições.
            Acompanhe o status da candidatura com seu Representante de chapa.
          </p>
        </div>
      </div>
    </div>
  </Layout>
</template>

<script>
import Vue from "vue";
import Layout from "@/components/layout/subscription/Layout.vue";

export default Vue.extend({
  name: "SubscriptionSuccessful",
  components: {
    Layout,
  },
});
</script>
